// @ts-nocheck
import React from 'react'
import { graphql, navigate } from 'gatsby'
import { GridContainer, Layout, PageHeader } from '@ggs/components/layout'
import { Component } from '@ggs/gatsby/components'
import Grid from '@mui/material/Grid'
import { SidebarMenu } from '@ggs/components/nav'

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const MultiStepPage = ({ queries: { site = '' } = {}, pageContext, ...props }) => {
  const data = pageContext?.data || {}
  const { title = '', content = data?.landingPage, sidebarMenu, parentPage } = data

  const sidebarMenuHandler = ({ uri }) => {
    navigate(uri)
  }

  console.log('MultiStepPageData: ', data)

  const contextSx = {
    '& .grid-container': {
      py: {
        xs: 3,
        lg: 0,
      },
      px: {
        lg: 6,
      },
    },
    '.multi-step-page__content': {
      '.grid-container, .grid-container__inner': {
        px: {
          lg: '0 !important',
        },
        mx: {
          lg: '0 !important',
        },
      },
      '> :nth-child(1)': {
        '.grid-container, .grid-container__inner': {
          pt: '0 !important',
          mt: '0 !important',
        },
      },
      '.text-card': {
        '.grid-container__inner': {
          py: {
            lg: '40px !important',
          },
        },
      },
    }
    ,
  }
  return (
    <Layout
      className="homepage"
      site={site}
      pageContext={pageContext}
      sx={
        {
          // background: colors.ultraLightBlue,
        }
      }
    >
      <PageHeader
        title={title}
        useFlare={false}
        sx={{
          pt: {
            xs: 3,
            lg: 'inherit',
          },
          '.grid-container__inner': {
            pb: '0 !important',
          },
        }}
        backLink={parentPage}
      />
      <GridContainer sx={contextSx}>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={3} zIndex={2}>
            <SidebarMenu menuItems={sidebarMenu} onClick={sidebarMenuHandler} />
          </Grid>
          <Grid className={'multi-step-page__content'} item xs={12} lg={9} zIndex={1}>
            {content.map((/** @type {JSX.IntrinsicAttributes & { [x: string]: any; }} */ item) =>
              item?.type ? <Component key={item.id} {...item} /> : null
            )}
          </Grid>
        </Grid>
      </GridContainer>
    </Layout>
  )
}

export const query = graphql`
  query multiStepPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default MultiStepPage
